import React from "react";
import "../css/Card.css";

const Card = ({ img, name, description }) => {
  return (
    <div className="grid-item">
      <div className="p-card-content">
        <div className="p-card-img-container">
          <div className="p-card-img">
            <img
              src={require(`../${img}`)}
              alt={name}
              style={{ maxHeight: "600px" }}
            />
          </div>
        </div>
        <div className="p-card-info text-center text-white">
          <div className="display-4">{name}</div>
          <div>
            <p style={{ fontSize: "2em" }}>{description}</p>
          </div>
          <div className="p-card-order"></div>
        </div>
      </div>
    </div>
  );
};
Card.defaultProps = {
  img: "",
};
export default Card;
