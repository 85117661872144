import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import ScrollAnimation from "react-animate-on-scroll";

import Award1 from "../assets/images/PSMMC.png";
import Award2 from "../assets/images/KSAUH-Logo.png";
import Award3 from "../assets/images/MinistryOfEducation.png";
import Award4 from "../assets/images/TEDxAward.png";
import FirstParagraph from "../assets/images/FirstParagraph.png";
import SecondParagraph from "../assets/images/SecondParagraph.png";

import "../css/content.css";

class Main extends React.Component {
  state = { value: 0 };
  onChange = value => {
    this.setState({ value });
  };
  render() {
    return (
      <div>
        <div className="container" style={{ paddingTop: "100px" }} id="aboutus">
          <div className="row">
            <div className="col-12 col-lg-6">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <div className="display-3">Vision</div>
                <div className="" style={{ fontSize: "22px" }}>
                  The water is a daily need for human life. Our company has
                  devoted its expertise in the field of water since 1990 in
                  order to contribute to provide balanced natural water to the
                  consumer under the slogan "Balanced natural water is not just
                  a goal, it is a lifestyle"
                </div>
              </ScrollAnimation>
            </div>
            <div
              className="col-12 col-lg-6"
              style={{ maxWidth: "700px", padding: "2rem" }}
            >
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <img src={FirstParagraph} alt="" width="100%" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div className="col-12 content-sperator d-lg-block d-none"></div>
          </div>
          <div className="row">
            <div
              className="col-12 col-lg-6 order-2 order-lg-1"
              style={{ maxWidth: "700px", padding: "2rem" }}
            >
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <img src={SecondParagraph} alt="" width="100%" />
              </ScrollAnimation>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce>
                <div className="display-3">Quality</div>
                <div className="" style={{ fontSize: "22px" }}>
                  Since water quality is a serious matter, our company carefully
                  selected the places of its wells. Nor environmental or
                  industrial pollution are existed on our sites to ensure water
                  quality. In our company, water purification and filtering
                  stages are carried out with the latest technologies. Also, our
                  water composition is naturally balanced without any injection
                  or removal of any of its combination.
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="row">
            <div className="col-12 content-sperator ">
              <div className="display-3" align="middle">
                Our Contributions
              </div>
            </div>
          </div>
          <div className="carouselContainer">
            <Carousel
              value={this.state.value}
              onChange={this.onChange}
              arrowRight={<i className="fas fa-chevron-right fa-2x trans"></i>}
              arrowLeft={<i className="fas fa-chevron-left fa-2x trans"></i>}
              infinite
              slides={ContImgs}
              clickToChange
              slidesPerPage={3}
              addArrowClickHandler
              centered
            />
          </div>

          <div className="row">
            <div className="col-12 content-sperator d-lg-block d-none"></div>
          </div>
        </div>
      </div>
    );
  }
}

const ContImgs = [
  <img src={Award1} alt="Ministry Of Defence" className="carousel-img" />,
  <img
    src={Award2}
    alt="King Saud university for health science"
    className="carousel-img"
  />,
  <img src={Award3} alt="Ministry Of Education" className="carousel-img" />,
  <img src={Award4} alt="TEDX" className="carousel-img" />
];

export default Main;
