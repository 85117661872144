import React from "react";
import Card from "./Card";
import Carousel from "@brainhubeu/react-carousel";
import "../css/Cardcontainer.css";
import { waterData } from "../_DATA.js";

class Cardcontainer extends React.Component {
  state = { value: 0 };
  onChange = (value) => {
    this.setState({ value });
  };
  render() {
    return (
      <div className="main-card" id="products">
        <div className="swiper-wrapper" style={{ padding: "0" }}>
          <Carousel
            value={this.state.value}
            onChange={this.onChange}
            slides={bannerImgs}
            breakpoints={{
              991: { slidesPerPage: 1 },
            }}
            clickToChange
            slidesPerPage={5}
            arrowRight={
              <i
                className="fas fa-chevron-right fa-2x trans pr-4"
                style={{ fontSize: "3.5rem", color: "#fff" }}
              ></i>
            }
            arrowLeft={
              <i
                className="fas fa-chevron-left fa-2x trans p-4"
                style={{ fontSize: "3.5rem", color: "#fff" }}
              ></i>
            }
            addArrowClickHandler
            centered
          />
        </div>
      </div>
    );
  }
}

const bannerImgs = [
  <Card
    img={waterData[0].img}
    name={waterData[0].name}
    description={waterData[0].description}
  />,
  <Card
    img={waterData[1].img}
    name={waterData[1].name}
    description={waterData[1].description}
  />,
  <Card
    img={waterData[2].img}
    name={waterData[2].name}
    description={waterData[2].description}
  />,
  <Card
    img={waterData[3].img}
    name={waterData[3].name}
    description={waterData[3].description}
  />,
  <Card
    img={waterData[4].img}
    name={waterData[4].name}
    description={waterData[4].description}
  />,
];

export default Cardcontainer;
