import React from "react";
import "../css/footer.css";
import Banner1 from "../assets/images/WhiteBanner.png";

const Footer = () => {
  return (
    <div className="main-footer text-white" id="contact">
      <div className="container">
        <div className="d-flex row justify-content-center">
          <div className="col-12 d-lg-block d-none">
            <div className="">
              <img
                className="Colored"
                src={Banner1}
                alt="شعار دنيا"
                height="80%"
                width="100%"
              ></img>
            </div>
          </div>

          {/* Column1 */}

          <div className="col-5 col-md-4">
            <div style={{ fontSize: "2.5rem" }}>اتصل بنا</div>
            <ul className="list-unstyled Li-text">
              <li>info@doniawater.com</li>
              <li>السعودية، الرياض</li>
              <li>طريق الخرج، ١٦٣٥٩</li>
            </ul>
          </div>

          {/* Column3 */}
          <div className="col-7 col-md-2">
            <h1>تواصل معنا</h1>

            <ul className="list-unstyled ">
              <li className="ul-li instalogo">
                <a href="https://www.instagram.com/donia.water/">
                  <i className="fab fa-instagram space"></i>
                </a>
              </li>
              <li className="ul-li whatslogo">
                <a href="https://api.whatsapp.com/send?phone=966558558264">
                  <i className="fab fa-whatsapp space"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-12 text-center">
          <div>&copy;مياه دنيا، جميع الحقوق محفوظة ٢٠٢٠</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
