import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./components/App";
import AppAr from "./componentsAR/AppAr";
import { BrowserRouter, Route } from "react-router-dom";

const userLang = navigator.language || navigator.userLanguage;

ReactDOM.render(
  <BrowserRouter>
    <Route exact path="/">
      {userLang === "ar" ? <AppAr /> : <App />}
    </Route>
    <Route exact path="/en">
      <App />
    </Route>
    <Route exact path="/ar">
      <AppAr />
    </Route>
  </BrowserRouter>,
  document.getElementById("root")
);
