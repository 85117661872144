import React from "react";
import Navbar from "./Navbar";
import Banner from "./banner";
import Cardcontainer from "./Cardcontainer";
import Carouselslider from "./Carouselslider";
import Footer from "./Footer";
import Main from "./Main";
import "../css/App.css";

import ScrollAnimation from "react-animate-on-scroll";

class AppAr extends React.Component {
  state = { wWidth: window.innerWidth, loading: true };

  componentDidMount() {
    window.addEventListener("resize", this.onScreenResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onScreenResize);
  }
  onLoadHandler = () => {
    this.setState({
      loading: false
    });
  };
  onScreenResize = () => {
    this.setState({
      wWidth: window.innerWidth
    });
  };

  render() {
    return (
      <div>
        {this.state.loading === true && (
          <div className="loading d-flex justify-content-center align-items-center">
            <div
              class="spinner-border"
              style={{
                width: "20rem",
                height: "20rem",
                fontSize: "50px",
                color: "#9848a7"
              }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <ScrollAnimation animateIn="fadeIn" animateOnce delay={0}>
          <Navbar />
          <div className="banner-container">
            <Banner
              loading={this.state.loading}
              onLoadHandler={this.onLoadHandler}
            />
            {this.state.wWidth > 575 ? <Carouselslider /> : <Cardcontainer />}
          </div>
          <Main />
          <Footer />
        </ScrollAnimation>
      </div>
    );
  }
}

export default AppAr;
