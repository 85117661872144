export const waterData = [
  {
    id: 1,
    name: "200 ML",
    img: "assets/images/200ml.png",
    description:
      "The 200mL bottles are suitable for school backpacks, home and it's elegant for gatherings.",
    bottlesPerBox: 48,
    price: 0,
  },
  {
    id: 2,
    name: "330 ML",
    img: "assets/images/330ml.png",
    description:
      "The 330mL bottles are perfect for use at the office, corporate events and at home. ",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 3,
    name: "600 ML",
    img: "assets/images/600ml.png",
    description:
      "The 600mL bottles are the best choice for fitness, travel and A large-scale events.",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 4,
    name: "5 L",
    img: "assets/images/5L.png",
    description:
      "The 5L bottles are the second largest of the DONIAWATER line, made for long road-trips, camping and hiking. ",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 5,
    name: "16 L",
    img: "assets/images/16L.png",
    description:
      "The 16L bottles are the first and largest of the DONIAWATER line, perfect for long-term savings, A large family sizes and suitable for all water coolers.  ",
    bottlesPerBox: 40,
    price: 0,
  },
];

export const waterDataAR = [
  {
    id: 1,
    name: "٢٠٠ مل",
    img: "assets/images/200ml.png",
    description:
      "عبوة الحجم ٢٠٠ مل مناسبة للحقائب المدرسية والمنزل وأنيقة للمناسبات والتجمعات",
    bottlesPerBox: 48,
    price: 0,
  },
  {
    id: 2,
    name: "٣٣٠ مل",
    img: "assets/images/330ml.png",
    description: "عبوة الحجم ٣٣٠ مل مثالية للمكاتب واجتماعات العمل",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 3,
    name: "٦٠٠ مل",
    img: "assets/images/600ml.png",
    description:
      "عبوة الحجم ٦٠٠ مل هي الخيار الأمثل للأنشطة الرياضية والسفر وتجمعات المرافق العامة ",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 4,
    name: "٥ لتر",
    img: "assets/images/5L.png",
    description:
      "عبوة الحجم ٥ لتر هي ثاني أكبر حجم في مجموعتنا، مصممه للرحلات البرية والتخييم وكذلك التنزه",
    bottlesPerBox: 40,
    price: 0,
  },
  {
    id: 5,
    name: "١٦ لتر",
    img: "assets/images/16L.png",
    description:
      "عبوة الحجم ١٦ لتر هي أكبر حجم في مجموعتنا، خيارك الأمثل للإدخار للمدى البعيد، صالحه للإستخدام لجميع أنواع مبردات الماء",
    bottlesPerBox: 40,
    price: 0,
  },
];
