import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import "../css/carouselslider.css";

import B330ml from "../assets/images/330ml.png";
import B220ml from "../assets/images/200ml.png";
import B600ml from "../assets/images/600ml.png";
import B5l from "../assets/images/5L.png";
import B16l from "../assets/images/16L.png";

import { waterDataAR } from "../_DATA.js";

class Carouselslider extends React.Component {
  state = { value: 0 };
  onChange = value => {
    this.setState({ value });
  };
  render() {
    const { value } = this.state;
    return (
      <div className="main-card" id="products">
        <div
          className="swiper-wrapper"
          style={{ background: "rgba(9,9,31,.6)", borderRadius: "33px" }}
        >
          <div
            style={{
              background: "rgba(4,28,44,.9)",
              width: "350px",
              height: "430px",
              margin: "150px 0px 0px 50px",
              position: "absolute",
              zIndex: "1",
              borderRadius: "33px"
            }}
            className="d-none d-lg-block"
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                padding: "50px 30px"
              }}
            >
              <br />

              <div
                style={{
                  fontSize: "30px",
                  color: "white",
                  opacity: 1,
                  textAlign: "center"
                }}
              >
                {waterDataAR[value].name}
              </div>
              <hr style={{ background: "white" }} />
              <div style={{ fontSize: "18px", color: "white", opacity: 1 }}>
                {waterDataAR[value].description}
              </div>
            </div>
          </div>
          <Carousel
            value={this.state.value}
            onChange={this.onChange}
            slides={bannerImgs}
            breakpoints={{
              991: { slidesPerPage: 3 }
            }}
            clickToChange
            slidesPerPage={5}
            centered
          />
        </div>
      </div>
    );
  }
}

const bannerImgs = [
  <img src={B220ml} alt={waterDataAR[0].name} style={{ maxHeight: "600px" }} />,
  <img src={B330ml} alt={waterDataAR[1].name} style={{ maxHeight: "600px" }} />,
  <img src={B600ml} alt={waterDataAR[2].name} style={{ maxHeight: "600px" }} />,
  <img src={B5l} alt={waterDataAR[3].name} style={{ maxHeight: "600px" }} />,
  <img
    src={B16l}
    alt={waterDataAR[4].name}
    style={{
      paddingLeft: "200px",
      maxHeight: "600px"
    }}
  />
];

export default Carouselslider;
