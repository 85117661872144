import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import ScrollAnimation from "react-animate-on-scroll";

import Award1 from "../assets/images/PSMMC.png";
import Award2 from "../assets/images/KSAUH-Logo.png";
import Award3 from "../assets/images/MinistryOfEducation.png";
import Award4 from "../assets/images/TEDxAward.png";
import FirstParagraph from "../assets/images/FirstParagraph.png";
import SecondParagraph from "../assets/images/SecondParagraph.png";
import "../css/content.css";

class Main extends React.Component {
  state = { value: 0 };
  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    return (
      <div>
        <div className="container" style={{ paddingTop: "100px" }} id="Aboutus">
          <div className="row">
            <div className="col-12 col-lg-6">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={FirstParagraph} alt="" width="100%" />
              </ScrollAnimation>
            </div>
            <div
              className="col-12 col-lg-6"
              style={{ maxWidth: "700px", padding: "2rem" }}
            >
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <div className="display-3" style={{ textAlign: "right" }}>
                  رؤيتنا
                </div>
                <div
                  className=""
                  style={{ fontSize: "22px", textAlign: "right" }}
                >
                  وعيًا من الشركة بما يمثله الماء من احتياج يومي لحياة الإنسان،
                  كرست الشركة خبرتها في مجال المياه منذ عام 1990 من أجل المساهمة
                  في تقديم مياه طبيعية متوازنة للمستهلك تحت شعار الماء الطبيعي
                  المتوازن ليس مجرد هدف وإنما أسلوب حياه
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div className="col-12 content-sperator d-lg-block d-none"></div>
          </div>
          <div className="row">
            <div
              className="col-12 col-lg-6 order-2 order-lg-1"
              style={{ maxWidth: "700px", padding: "2rem" }}
            >
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="display-3" style={{ textAlign: "right" }}>
                  جودة الماء
                </div>
                <div
                  className=""
                  style={{ fontSize: "22px", textAlign: "right" }}
                >
                  بما أن جودة الماء واجب ديني و مطلب أساسي فبناءً على ذلك تم
                  اختيار الموقع المناسب لمنبع المياه الجوفية بعيدًا عن التلوث
                  البيئي و الصناعي، حيث تتم مراحل تكرير المياه بأحدث التقنيات
                  محافظين على تركيبتها الطبيعية بدون حقن أملاح صناعية
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce>
                <img src={SecondParagraph} alt="" width="100%" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div className="col-12 content-sperator ">
              <div className="display-3" align="middle">
                مُسـاهمـاتُنا
              </div>
            </div>
          </div>
          <div className="carouselContainer">
            <Carousel
              value={this.state.value}
              onChange={this.onChange}
              arrowRight={<i className="fas fa-chevron-right fa-2x trans"></i>}
              arrowLeft={<i className="fas fa-chevron-left fa-2x trans"></i>}
              infinite
              slides={ContImgs}
              clickToChange
              slidesPerPage={3}
              addArrowClickHandler
              centered
            />
          </div>
          <div className="row">
            <div className="col-12 content-sperator d-lg-block d-none"></div>
          </div>
        </div>
      </div>
    );
  }
}

const ContImgs = [
  <img src={Award1} alt="وزارة الدفاع" className="carousel-img" />,
  <img
    src={Award2}
    alt="جامعة الملك سعود للعلوم الصحية"
    className="carousel-img"
  />,
  <img src={Award3} alt="وزارةالتعليم" className="carousel-img" />,
  <img src={Award4} alt="TEDx" className="carousel-img" />,
];

export default Main;
