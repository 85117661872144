import React from "react";

import { Transition } from "react-transition-group";
import { HashLink as Link } from "react-router-hash-link";

import WhiteLogo from "../assets/images/LogoWhite.png";
import "../css/Navbar.css";

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: "none" },
};

class Navbar extends React.Component {
  state = { scrollY: 50, toggleActive: false, wWidth: window.innerWidth };

  componentDidMount() {
    window.addEventListener("scroll", this.onScrollHandler);
    window.addEventListener("resize", this.onScreenResize);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHandler);
    window.removeEventListener("resize", this.onScreenResize);
  }
  // Handlers
  onScrollHandler = () => {
    const { scrollY } = window;

    this.setState({
      scrollY,
    });
  };

  onScreenResize = () => {
    this.setState({
      wWidth: window.innerWidth,
    });
  };

  onClickHandler = () => {
    this.setState({
      toggleActive: !this.state.toggleActive,
    });
  };
  //

  render() {
    const { scrollY, toggleActive, wWidth } = this.state;

    const defaultStyle = {
      transition: `opacity 500ms ease-in-out`,
      opacity: `${wWidth > 637 ? "1" : "0"}`,
    };

    return (
      <nav
        className={scrollY > 50 ? "myNav shadowStroke affix " : "myNav stroke"}
        style={toggleActive ? { background: "#111" } : {}}
      >
        <div className="navContain">
          <div className="logo">
            <Link smooth to="/ar#banner">
              <img
                id="mainLogo"
                alt="شعار دنيا"
                src={WhiteLogo}
                height={scrollY > 50 ? "65px" : "100px"}
              />
            </Link>
          </div>
          <Transition in={toggleActive} timeout={300}>
            {(state) => (
              <div
                id="mainListDiv"
                className="main_list"
                style={
                  wWidth > 637
                    ? { opacity: 1 }
                    : {
                        ...defaultStyle,
                        ...transitionStyles[state],
                      }
                }
              >
                <ul className="navlinks">
                  <li>
                    <Link
                      smooth
                      to="/ar#products"
                      onClick={this.onClickHandler}
                    >
                      المنتجات
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/ar#Aboutus" onClick={this.onClickHandler}>
                      عن دُنيا
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/ar#contact" onClick={this.onClickHandler}>
                      تواصل معنا
                    </Link>
                  </li>
                  <li>
                    <Link smooth to="/en#banner" onClick={this.onClickHandler}>
                      English
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Transition>

          <span
            className={toggleActive ? "navTrigger active" : "navTrigger"}
            onClick={this.onClickHandler}
          >
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
      </nav>
    );
  }
}

export default Navbar;
