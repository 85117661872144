import React from "react";
import "../css/banner.css";
import ScrollAnimation from "react-animate-on-scroll";

import b330ml from "../assets/images/banner-bottle.png";
import b330mlweb from "../assets/images/banner-bottle.webp";

class Banner extends React.Component {
  state = { wHeight: window.innerHeight, loading: this.props.loading };

  componentDidMount() {
    window.addEventListener("resize", this.onScreenResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onScreenResize);
  }
  onScreenResize = () => {
    this.setState({
      wHeight: window.innerHeight
    });
  };
  render() {
    return (
      <div className="" id="banner">
        <div
          className="container-fuild"
          style={{ maxWidth: "1140px", margin: "0 auto" }}
        >
          <div
            className="row banner-height"
            style={{ height: this.state.wHeight, maxHeight: "1400px" }}
          >
            <div className="col-6 ">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className=" text-left" style={{ marginLeft: "30px" }}>
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      animateOnce
                      delay={750}
                    >
                      <div className="display-1  text-white banner-header">
                        Donia Water
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      animateOnce
                      delay={1000}
                    >
                      <div className="display-4 text-white banner-sub ">
                        Natural, Balanced
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-12 banner-separator"></div>
                <div className="col-12">
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    animateOnce
                    delay={1250}
                  >
                    <a
                      href="https://api.whatsapp.com/send?phone=966558558264"
                      className="banner-button btn-1"
                    >
                      <span className="button-text">Order Now</span>
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
            </div>

            <div className="col-6 align-self-end">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce
                delay={250}
                offset={0}
              >
                <picture>
                  <source
                    srcset={b330mlweb}
                    onLoad={this.props.onLoadHandler}
                    type="image/webp"
                  />
                  <img
                    src={b330ml}
                    alt=""
                    className="banner-bottle"
                    onLoad={this.props.onLoadHandler}
                  />
                </picture>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
